<template>
  <div>
    <table>
      <tbody>
        <tr class="itens">
          <th>
            <div class="mt-2">
              <input
                placeholder="SKU"
                type="text"
                :class="
                  'prod-cod item-bipagem insumo-' +
                  insumo.insumoId +
                  (insumo.bipado ? ' insumoBipado' : '') +
                  (insumo.loading ? ' input-loading' : '') +
                  'font-weight-bold bg-red'
                "
                @keyup.enter="biparInsumo($event, insumo)"
                :value="insumo.bipado ? insumo.winthorCodigoProduto : ''"
                :disabled="insumo.bipado || insumo.loading || loading"
              />
            </div>
          </th>
          <th>
            <div v-if="!insumo.bipado">
              <b-button
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
              >
                <b-icon
                  icon="check-circle"
                  scale="2"
                ></b-icon>
              </b-button>
            </div>
            <div v-else>
              <b-button
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
              >
                <b-icon
                  scale="2"
                  icon="check-circle-fill"
                  variant="success"
                ></b-icon>
              </b-button>
            </div>
          </th>
          <th>
            <b-button
              v-if="insumo.bipado"
              aria-pressed="false"
              size="lg"
              variant="transparent"
              class="mt-4 rounded-circle p-2"
              @click="limparValorDaBipagem($event, insumo.insumoId)"
            >
              <b-icon
                icon="x-circle"
                scale="2"
                variant="danger"
              ></b-icon>
            </b-button>
          </th>
          <th>
            <div v-if="insumo.bipado && insumo.serial !== ''">
              <b-button
                v-if="false"
                :disabled="
                  !permiteBotaoMoverInsumo || insumo.bipado || insumo.loading
                "
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
                aria-pressed="false"
                @click="modalMoverInsumo(insumo.insumoId, insumo.volumeId)"
              >
                <b-iconstack
                  variant="transparent"
                  font-scale="1.3"
                >
                  <b-icon
                    stacked
                    scale="2"
                    icon="arrows-move"
                    variant="warning"
                  ></b-icon>
                  <b-icon
                    stacked
                    scale="0.3"
                    icon="circle-fill"
                    variant="warning"
                  ></b-icon>
                </b-iconstack>
              </b-button>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { BipagemService } from "@/services/pedido_web_producao/linha_diversos/BipagemService";
import { UsuarioLogado } from "@/services/login/UsuarioLogado";
import BipagemInsumosRepository from "@/repositories/v2/BipagemInsumoRepository";
export default {
  components: {},
  name: "ListagemInsumosNoVolumeLinhaDiversos",
  data: () => ({
    bodyMoverInsumo: {},
    bipagemService: BipagemService.build(),
    bipagemRepository: BipagemInsumosRepository.build(),
  }),
  async created() {
    this.bipagemService = BipagemService.build().setVm(this);
  },
  props: {
    volumes: { type: Array, required: true },
    insumo: { type: Object, required: true },
    totalInsumos: { type: Number, required: true },
    loading: { type: Boolean, required: true },
    detalhes: { type: Boolean, default: false },
  },
  computed: {
    permiteBotaoMoverInsumo() {
      return UsuarioLogado.getPerfil().includes("administrador");
    },
  },
  methods: {
    modalMoverInsumo(insumoId, volumeId) {
      let bodyMoverInsumo = {
        insumoIdNumber: insumoId,
        indexVol: this.indexVol,
        volumeId: volumeId,
      };
      this.$emit("abrirModalMoverInsumo", bodyMoverInsumo);
      this.$bvModal.show("modal-mover-insumo");
    },

    async limparValorDaBipagem(event, insumoId) {
      const { target } = event;
      const serial = target.value;
      target.value = "";
      if (this.insumo.insumoId === insumoId) {
        this.insumo.bipado = false;
        this.insumo.serial = "";
      }
      target.focus();
      this.bipagemRepository.deleteBipagem({ insumoId }).then(({ status }) => {
        if (status === 200) return;
        if (this.insumo.insumoId === insumoId) {
          this.insumo.bipado = true;
          this.insumo.serial = serial;
        }
      });
    },

    async biparInsumo(event, insumo) {
      this.insumo.serial = event.target.value;
      let bodyValidarBipagem = {
        insumo: insumo,
        event: event,
      };
      this.$emit("biparInsumo", bodyValidarBipagem);
    },
  },
};
</script>
<style scoped>
.insumoBipado {
  border: 2px solid green;
  border-radius: 10px;
}

.input-loading {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
}
</style>
