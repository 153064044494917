<template>
  <div>
    <div class="content">
      <div class="box-content">
        <div class="pedido-det">
          <div v-if="insumosForamCarregados">
            <cabecalho-linha-diversos
              :numeroPedido="pedido.numeroPedido"
              :nomeCliente="pedido.clienteNome"
              :transportadora="pedido.transportadoraNome"
              :data-producao="pedido.dataProducao | dateDMY"
              :linha-status="pedido.linhaStatus"
              :quantidade-caixas="quantidadeVolumes"
              :insumos="insumosCabecalho"
            />
            <div class="px-3">
              <b-row align-h="around">
                <default-button-caixa
                  :nameButton="'Detalhar Caixas'"
                  class="text-center my-4"
                  @click="detalharCaixas"
                />
                <default-button-caixa
                  v-if="mostraBtnFecharCaixa"
                  :nameButton="'Fechar Caixa'"
                  class="text-center my-4 btn-fechar"
                  @click="fecharCaixa"
                  :loading="btnFecharCaixaLoading"
                  :disabled="!habilitaBtnFecharCaixa"
                />
                <default-button-caixa
                  v-if="mostraBtnFinalizarLinha"
                  :nameButton="'Finalizar Linha'"
                  class="text-center my-4"
                  @click="finalizarLinha"
                  :loading="btnFinalizarLinhaLoading"
                />
              </b-row>
              <listagem-caixas-linha-diversos
                :insumos="insumos"
                :volumes="volumes"
                :loading="loadingTela"
                :btn-fechar-caixa-loading="btnFecharCaixaLoading"
                @biparInsumo="biparInsumo($event)"
                @volumes-update="volumes = $event"
              />
            </div>
          </div>
          <div v-else>
            <alerta-de-espera />
          </div>
        </div>
      </div>
      <modal-detalhes-de-caixas-linha-diversos
        :volumes="volumes"
        :quantidade-volumes="quantidadeVolumes"
      />
    </div>
    <div>
      <modal-fechar-caixa :modalAlert="modalAlert" />
    </div>
    <div>
      <modal-usuario-iniciador :alertUsuarioIniciador="alertUsuarioIniciador" />
    </div>
  </div>
</template>

<script>
import InsumoPedidoRepository from "@/repositories/v2/InsumoPedidoRepository";
import PedidoWebRepository from "@/repositories/v2/PedidoWebRepository";
import DefaultButtonCaixa from "@/modules/souserials/producao_pedido/components/DefaultButtonCaixa.vue";
import AlertaDeEspera from "@/components/AlertaDeEspera.vue";
import router from "@/router";
import CabecalhoLinhaDiversos from "../components/CabecalhoLinhaDiversos.vue";
import ListagemCaixasLinhaDiversos from "../components/ListagemCaixasLinhaDiversos.vue";
import ModalDetalhesDeCaixasLinhaDiversos from "../components/ModalDetalhesDeCaixasLinhaDiversos.vue";
import Swal from "sweetalert2";
import ModalFecharCaixa from "../../../ModalFecharCaixa/ModalFecharCaixa.vue";
import ModalUsuarioIniciador from "../../../ModalUsuarioIniciador.vue";
import { UsuarioIniciadorService } from "@/services/pedido_web_producao/UsuarioIniciadorService";
import { HttpRestService } from "@/services/http/HttpRestService";
export default {
  name: "FilialParaPedidoWebProducaoLinhaDiversos",
  components: {
    ModalDetalhesDeCaixasLinhaDiversos,
    ListagemCaixasLinhaDiversos,
    CabecalhoLinhaDiversos,
    AlertaDeEspera,
    DefaultButtonCaixa,
    ModalFecharCaixa,
    ModalUsuarioIniciador,
  },
  data: () => ({
    modalAlert: {},
    alertUsuarioIniciador: {},
    insumos: [],
    volumes: [],
    dataModalMoverInsumo: {},
    pedido: {
      clienteNome: "",
      dataProducao: "",
      kitDescricao: "",
      numeroPedido: 0,
      transportadoraCodigo: 0,
      transportadoraNome: "",
      linhaStatus: "",
    },
    repositories: {
      insumoPedidoRepository: InsumoPedidoRepository.build(),
      pedidoWebV2Repository: PedidoWebRepository.build(),
    },
    quantidadeVolumes: 0,
    btnFecharCaixaLoading: false,
    btnFinalizarLinhaLoading: false,
    loadingTela: false,
    usuarioIniciadorService: UsuarioIniciadorService.build(),
  }),
  async beforeCreate() {
    this.usuarioIniciadorService = UsuarioIniciadorService.build().setVm(this);
    this.loadingTela = true;
    await this.usuarioIniciadorService.usuarioIniciadorLinhaDiversos(
      this.$route.params.numped
    );
    this.loadingTela = false;
  },
  async mounted() {
    this.listarInsumos();
  },
  watch: {
    volumes: {
      handler(volumes) {
        for (const volume of volumes) {
          for (const insumo of volume.insumos) {
            if (insumo.quantidade < 0) {
              insumo.quantidade = 0;
            }
            const insumoCabecalho =
              this.insumos.find(
                (i) => i.insumoId === (insumo.insumo || {}).insumoId
              ) || {};
            const quantidadeRestante = insumoCabecalho.quantidadeRestante || 0;
            if (
              insumo.quantidade > quantidadeRestante &&
              volume.volumeId === 0
            ) {
              insumo.quantidade = quantidadeRestante;
            }
            insumo.quantidade = +insumo.quantidade;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    insumosForamCarregados() {
      return !this.loadingTela;
    },
    insumosCabecalhoNaoBipaveis() {
      return this.insumos.filter((i) => !i.precisaSerBipado);
    },
    insumosCabecalho() {
      return [
        ...this.insumosCabecalhoNaoBipaveis,
        ...this.insumosCabecalhoBipaveis,
      ];
    },
    insumosCabecalhoBipaveis() {
      return this.insumos
        .filter((i) => i.precisaSerBipado)
        .map((insumo, i, arr) => {
          const insumoMapped = { ...insumo };
          insumoMapped.quantidadeRestante = arr
            .filter(
              (i) =>
                i.winthorCodigoProduto === insumo.winthorCodigoProduto &&
                !i.bipado
            )
            .reduce((acc, rInsumo) => acc + rInsumo.quantidadeRestante, 0);
          insumoMapped.quantidade = arr.filter(
            (i) => i.winthorCodigoProduto === insumo.winthorCodigoProduto
          ).length;
          return insumoMapped;
        })
        .filter((insumo, index, arr) => {
          return (
            index ===
            arr.findIndex(
              (i) => i.winthorCodigoProduto === insumo.winthorCodigoProduto
            )
          );
        });
    },
    volumeCoringa() {
      return this.volumes.find((v) => !v.volumeId) || {};
    },
    mostraBtnFinalizarLinha() {
      return (
        this.insumos.reduce(
          (acc, insumo) => acc + insumo.quantidadeRestante,
          0
        ) === 0 && this.pedido.linhaStatus !== "FINALIZADO"
      );
    },
    habilitaBtnFecharCaixa() {
      return (
        this.volumes.find((v) => v.volumeId === 0) || { insumos: [] }
      ).insumos.reduce((acc, i) => acc + i.quantidade, 0);
    },
    mostraBtnFecharCaixa() {
      return (
        !this.mostraBtnFinalizarLinha &&
        this.existeVolumeCoringa &&
        this.pedido.linhaStatus !== "FINALIZADO"
      );
    },
    existeVolumeCoringa() {
      return this.volumeCoringa.volumeId !== undefined;
    },
    numSeqBipados() {
      return this.insumos
        .filter((i) => i.bipado && !i.volumeId)
        .map((i) => ({ sspedidoNumSeq: i.sspedidoNumSeq, quantidade: 1 }));
    },
  },
  methods: {
    async listarInsumos() {
      this.loadingTela = true;
      const response =
        await this.repositories.insumoPedidoRepository.getLinhaDiversos(
          this.$route.params.numped
        );
      const { data } = response.data;
      this.loadingTela = false;
      this.renderizarLista(data);
      this.loadingTela = false;
    },
    renderizarLista(data) {
      this.insumos = data.insumos.map((i, index) => {
        const {
          insumoDescricao,
          insumoId,
          quantidade,
          quantidadeRestante,
          sspedidoNumSeq,
          winthorCodigoProduto,
          precisaSerBipado,
          serial,
          bipado,
        } = i;
        return {
          descricao: insumoDescricao,
          insumoId,
          quantidade,
          quantidadeRestante,
          sspedidoNumSeq,
          winthorCodigoProduto,
          precisaSerBipado,
          index: index + 1,
          serial,
          bipado,
        };
      });
      this.volumes = data.volumes.map((v) => {
        return {
          codigo: v.codigo,
          insumos: v.insumos.map((i) => {
            return {
              insumo: this.insumos.find(
                (insumo) => insumo.insumoId === i.insumoId
              ),
              quantidade: i.quantidade,
            };
          }),
          volumeId: v.volumeId,
        };
      });

      this.volumes = this.volumes.map((v) => ({
        ...v,
        insumosBipaveis: v.insumos.filter((i) => i.insumo.precisaSerBipado),
        insumosNaoBipaveis: v.insumos.filter((i) => !i.insumo.precisaSerBipado),
      }));

      this.pedido = {
        clienteNome: data.clienteNome,
        dataProducao: data.dataProducao,
        kitDescricao: data.kitDescricao,
        numeroPedido: data.numeroPedido,
        transportadoraCodigo: data.transportadoraCodigo,
        transportadoraNome: data.transportadoraNome,
        linhaStatus: (data.linhaProducao3Status || "").toUpperCase(),
      };
      this.quantidadeVolumes = this.volumes.length;
      this.loadingTela = false;
    },
    async fecharCaixa() {
      const sspedidoNumSeqList = this.volumeCoringa.insumos
        .filter((insumo) => insumo.insumo.bipado && insumo.quantidade > 0)
        .map((insumo) => ({
          quantidade: insumo.quantidade,
          sspedidoNumSeq: insumo.insumo.sspedidoNumSeq,
        }));
      this.btnFecharCaixaLoading = true;
      try {
        const body = { sspedidoNumSeqList, linha: 3 };
        const response =
          await this.repositories.pedidoWebV2Repository.criarVolumeDiversos(
            this.pedido.numeroPedido,
            body
          );
        if (response.status === 200) {
          this.renderizarLista(response.data.data);
        }
        if (response.status === 500) {
          await Swal.fire({
            icon: "error",
            text: "Ocorreu um problema no servidor.",
          });
          window.location.reload();
        } else {
          this.modalAlert = {
            message: response.data.message,
            variant: response.status === 200 ? "success" : "danger",
          };
          this.$bvModal.show("modal-fechar-caixa");
        }
      } catch (e) {
        alert("Ocorreu um erro inesperado. Entre em contato com o suporte.");
      }
      this.btnFecharCaixaLoading = false;
    },
    async finalizarLinha() {
      this.btnFinalizarLinhaLoading = true;
      try {
        const response =
          await this.repositories.pedidoWebV2Repository.finalizarLinhaDiversos(
            this.pedido.numeroPedido
          );
        if (response.status === 200) {
          alert("A linha foi finalizada com sucesso.");
          await router.replace({ path: "/linha-prod" });
        } else {
          alert(
            response.data.message || "Ocorreu um erro na finalização da linha."
          );
        }
      } catch (e) {
        console.log(e.message);
        this.btnFinalizarLinhaLoading = false;
      }
      this.btnFinalizarLinhaLoading = false;
    },
    detalharCaixas() {
      this.$bvModal.show("modal-detalhes-volume");
    },
    async biparInsumo({ event, insumo }) {
      const serial = event.target.value;
      insumo.serial = serial;
      const zerarSerial = () => {
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].serial = "";
            break;
          }
        }
      };

      const setLoading = (value) => {
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].loading = !!value;
            break;
          }
        }
      };

      const nextInsumoId = (() => {
        const currentInsumoWithIndex = this.volumeCoringa.insumos
          .map(({ insumo }, i) => ({ ...insumo, i }))
          .filter(({ insumoId }) => insumoId === insumo.insumoId)[0] || {
          i: -1,
        };
        const nextIndex = currentInsumoWithIndex.i + 1;
        return (
          (this.volumeCoringa.insumos[nextIndex] || { insumo: { insumoId: 0 } })
            .insumo || { insumo: { insumoId: 0 } }
        ).insumoId;
      })();

      if (!insumo.serial) {
        //alert("Esse SKU está vazio.");
        return;
      }

      setLoading(true);
      const response = await HttpRestService.post(
        `/api/v2/bipagem-insumo/serial/sku`,
        {
          serial,
          insumoId: insumo.insumoId,
        }
      );
      setLoading(false);
      if (response.status === 200) {
        console.log({ nextInsumoId });
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].bipado = true;
            break;
          }
        }
      } else {
        zerarSerial();
        event.target.focus();
        alert(response.data.message);
      }
    },
  },
};
</script>
